@charset "UTF-8";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q {
  &:before, &:after {
    content: "";
    content: none;
  }
}

blockquote {
  &:before, &:after {
    content: "";
    content: none;
  }
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

@font-face {
  font-family: "plume_adregular";
  src: url("/fonts/plume_std_ad-webfont.eot");
  src: url("/fonts/plume_std_ad-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/plume_std_ad-webfont.woff") format("woff"), url("/fonts/plume_std_ad-webfont.ttf") format("truetype"), url("/fonts/plume_std_ad-webfont.svg#plume_adregular") format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Conv_Plume-Bold";
  src: url("/fonts/Plume-Bold.eot");
  src: local("☺"), url("/fonts/Plume-Bold.woff") format("woff"), url("/fonts/Plume-Bold.ttf") format("truetype"), url("/fonts/Plume-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Conv_Plume-Regular";
  src: url("/fonts/Plume-Regular.eot");
  src: local("☺"), url("/fonts/Plume-Regular.woff") format("woff"), url("/fonts/Plume-Regular.ttf") format("truetype"), url("/fonts/Plume-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}


html {
  -webkit-text-size-adjust: none;
}

a {
  &:active, &:focus {
    border: none;
    outline: none;
  }
}

textarea, input {
  outline: none;
}

sup {
  position: relative;
  top: -0.75em;
  font-size: 60%;
}

#cookie-alert {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 25;
  background: black;
  background-color: rgba(0, 0, 0, 0.67);
  text-align: center;
  width: 100%;
  display: none;
}

.lt-ie9 #cookie-alert {
  background-image: url("/img/common/ie8_dim.png");
  background-color: transparent;
}

#cookie-alert .cookie-content {
  max-width: 740px;
  padding: 30px;
  margin: 0px auto;
  display: block;
  position: relative;
  h3 {
    font-family: "Trebuchet MS";
    color: #ffffff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    @media screen and (min-width: 519px) {
      font-size: 32px;
      padding: 10px 0px 40px 0px;
      margin: 0px auto;
    }
    @media screen and (max-width: 520px) {
      font-size: 22px;
      margin: 25px auto 0px auto;
      padding: 10px 0px 25px 0px;
    }
  }
}

.textshadow #cookie-alert .cookie-content h3 {
  text-shadow: 0px -2px 0px rgba(0, 0, 0, 0.5);
}

#cookie-alert .cookie-content p {
  font-family: "Trebuchet MS";
  color: #ffffff;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  margin: 0px auto;
  padding: 0px 0px 20px 0px;
      -webkit-font-smoothing: antialiased;
    @media screen and (min-width: 519px) {
      font-size: 16px;
    }
  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
}

.textshadow #cookie-alert .cookie-content p {
  text-shadow: 0px -2px 0px rgba(0, 0, 0, 0.5);
}

#cookie-alert {
  .cookie-content {
    p a {
      color: #ebbd13;
      text-shadow: none;
    }
    .btn-accept {
      margin: 22px auto 0px auto;
      background-image: url("/img/common/btn_green_sm.png");
      background-position: bottom right;
      background-repeat: no-repeat;
      padding-right: 40px;
      display: table;
      height: 36px;
      text-decoration: none;
      color: #ffffff;
      font-size: 14px;
      font-family: "Trebuchet MS";
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
      -webkit-font-smoothing: antialiased;
      span {
        background-image: url("/img/common/btn_green_sm.png");
        background-position: top left;
        background-repeat: no-repeat;
        display: block;
        height: 36px;
        width: inherit;
        padding-left: 40px;
        line-height: 34px;
      }
    }
  }
  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    display: block;
    z-index: 2;
    background-image: url("/img/common/btn_cookie_close.png");
    text-decoration: none;
    overflow: hidden;
    span {
      display: none;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  #cookie-alert .cookie-content .btn-accept {
    padding-right: 39px;
    span {
      padding-left: 39px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  #cookie-alert .cookie-content .btn-accept {
    padding-right: 39px;
    span {
      padding-left: 39px;
    }
  }
}

#header {
  width: 100%;
  position: fixed;
  display: block;
  z-index: 20;
  top: 0px;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.qr-toast {
  display: none;
}

.header-container {
  display: block;
  max-width: 1400px;
  width: 100%;
  margin: 0px auto;
  text-align: left;
  position: relative;
}

.header-left {
  width: 122px;
  height: 255px;
  display: block;
  background-image: url("/img/common/hdr_bg_side.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  pointer-events: none;
}

.mobile-menu {
  display: none;
}

.header-logo {
  position: absolute;
  z-index: 4;
  top: 22px;
  left: 38px;
  width: 170px;
  height: 136px;
  display: block;
  background-image: url("/img/common/logo_header.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  span {
    display: none;
  }
}

.header-nav {
  display: block;
  width: inherit;
  height: 92px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-image: none;
  background-color: transparent;
  .container {
    background-image: url("/img/common/hdr_bg.png");
    background-repeat: repeat-x;
    background-position: 0px 0px;
    margin-left: 112px;
    height: inherit;
    border: 1px;
    .holder {
      padding-top: 37px;
      background-image: url("/img/common/hdr_bg_top.png");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      ul {
        height: 52px;
        margin: 0px auto;
        display: table;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        *zoom: 1;
        li {
          float: left;
          padding: 0px 7px 0px 9px;
          background-image: url("/img/common/hdr_nav_divider.png");
          background-position: center left;
          background-repeat: no-repeat;
          &:first-child {
            padding-left: 0px;
            background-image: none;
          }
          &.selected a {
            background-image: url("/img/common/hdr_nav_select.png");
            background-position: bottom left;
            background-repeat: repeat-x;
          }
          a {
            font-family: "Trebuchet MS";
            font-size: 18px;
            color: #7f9910;
            text-decoration: none;
            padding-bottom: 7px;
            letter-spacing: -1px;
            background-position: bottom left;
            background-repeat: repeat-x;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
    }
  }
}

.textshadow .header-nav .container .holder ul li a {
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.no-touch .header-nav .container .holder ul li a:hover {
  background-image: url("/img/common/hdr_nav_select.png");
}

.header-nav .container .holder ul li.mobile-language {
  display: none;
}

.lt-ie9 .header-nav .container .holder ul li.highlight {
  max-height: 37px;
  position: relative;
  overflow: hidden;
  top: -8px;
  background-image: none;
  > a {
    background: url("/img/common/btn_green_sm.png") no-repeat right -36px;
    padding-right: 40px;
    display: block;
    height: 29px;
    text-decoration: none;
    color: #ffffff;
    font-size: 15px;
    font-family: "Trebuchet MS";
    font-weight: bold;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    span {
      background: url("/img/common/btn_green_sm.png") no-repeat top left;
      display: block;
      height: 36px;
      width: inherit;
      padding-left: 40px;
      line-height: 35px;
    }
  }
}

.no-touch .lt-ie9 .header-nav .container .holder ul li.highlight > a:hover {
  background: url("/img/common/btn_green_sm_over.png") no-repeat right -36px;
  span {
    background: url("/img/common/btn_green_sm_over.png") no-repeat top left;
  }
}

@media screen and (min-width: 960px) {
  .header-nav .container .holder ul li.highlight {
    max-height: 37px;
    position: relative;
    overflow: hidden;
    top: -8px;
    background-image: none;
    > a {
      background: url("/img/common/btn_green_sm.png") no-repeat right -36px;
      padding-right: 40px;
      display: block;
      height: 29px;
      text-decoration: none;
      color: #ffffff;
      font-size: 15px;
      font-family: "Trebuchet MS";
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
      -webkit-font-smoothing: antialiased;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      span {
        background: url("/img/common/btn_green_sm.png") no-repeat top left;
        display: block;
        height: 36px;
        width: inherit;
        padding-left: 40px;
        line-height: 35px;
      }
    }
  }
  .no-touch .header-nav .container .holder ul li.highlight > a:hover {
    background: url("/img/common/btn_green_sm_over.png") no-repeat right -36px;
    span {
      background: url("/img/common/btn_green_sm_over.png") no-repeat top left;
    }
  }
}

.header-right {
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  .background {
    width: 100%;
    display: block;
    height: 90px;
    background-image: url("/img/common/hdr_bg_social.png");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
  }
  .select-language {
    width: inherit;
    display: block;
    height: 21px;
    position: relative;
    z-index: 3;
    padding: 29px 0px 22px 0px;
    .control {
      display: block;
      width: auto;
      height: 21px;
      background-image: url("/img/common/hdr_lang_bg.png");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      position: absolute;
      right: 10px;
      ul {
        width: 84px;
        height: 21px;
        position: relative;
        z-index: 2;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        *zoom: 1;
        li {
          float: left;
          width: 40px;
          a {
            width: inherit;
            height: 17px;
            display: block;
            text-align: center;
            text-decoration: none;
            font-size: 12px;
            font-family: "Trebuchet MS";
            padding: 4px 0px 0px 0px;
            color: #917905;
          }
        }
      }
    }
  }
}

.textshadow .header-right .select-language .control ul li a {
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.35);
}

.en_GB .header-right .select-language .control ul li.en_GB, .de_DE .header-right .select-language .control ul li.de_DE, .fr_FR .header-right .select-language .control ul li.fr_FR, .fr_BE .header-right .select-language .control ul li.fr_BE, .nl_BE .header-right .select-language .control ul li.nl_BE, .it_IT .header-right .select-language .control ul li.it_IT, .de_CH .header-right .select-language .control ul li.de_CH, .fr_CH .header-right .select-language .control ul li.fr_CH {
  width: 42px;
}

.en_GB .header-right .select-language .control ul li.en_GB a, .de_DE .header-right .select-language .control ul li.de_DE a, .fr_FR .header-right .select-language .control ul li.fr_FR a, .fr_BE .header-right .select-language .control ul li.fr_BE a, .nl_BE .header-right .select-language .control ul li.nl_BE a, .it_IT .header-right .select-language .control ul li.it_IT a, .de_CH .header-right .select-language .control ul li.de_CH a, .fr_CH .header-right .select-language .control ul li.fr_CH a {
  background-image: url("/img/common/hdr_lang_btn.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  color: #ffffff;
}

.textshadow {
  .en_GB .header-right .select-language .control ul li.en_GB a, .de_DE .header-right .select-language .control ul li.de_DE a, .fr_FR .header-right .select-language .control ul li.fr_FR a, .fr_BE .header-right .select-language .control ul li.fr_BE a, .nl_BE .header-right .select-language .control ul li.nl_BE a, .it_IT .header-right .select-language .control ul li.it_IT a, .de_CH .header-right .select-language .control ul li.de_CH a, .fr_CH .header-right .select-language .control ul li.fr_CH a {
    text-shadow: 1px -1px 0px rgba(0, 0, 0, 0.35);
  }
}

.header-right .header-social {
  position: relative;
  z-index: 1;
  top: -2px;
  padding-left: 5px;
  ul {
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    *zoom: 1;
    li {
      width: 37px;
      height: 35px;
      float: left;
      a {
        width: inherit;
        height: inherit;
        display: block;
        overflow: hidden;
        text-decoration: none;
        background-image: url("/img/common/hdr_btn_social.png");
        background-repeat: no-repeat;
        background-position: 0px 0px;
        position: relative;
        span {
          display: none;
        }
      }
    }
  }
}

.no-touch .header-right .header-social ul li a:hover {
  top: 4px;
}

.csstransitions .header-right .header-social ul li a {
  transition: top 0.15s ease-in-out;
  -webkit-transition: top 0.15s ease-in-out;
  -moz-transition: top 0.15s ease-in-out;
  -o-transition: top 0.15s ease-in-out;
}

.header-right .header-social ul li {
  &.nav-contact a {
    background-position: 0px 0px;
  }
  &.nav-facebook a {
    background-position: -37px 0px;
  }
  &.nav-twitter a {
    background-position: -74px 0px;
  }
  &.nav-youtube a {
    background-position: -111px 0px;
  }
  &.nav-instagram a {
    background-position: -148px 0px;
  }
}

.header-csr {
  display: table;
  z-index: 10;
  position: absolute;
  width: 373px;
  top: 74px;
  left: 50%;
  text-align: center;
  margin-left: -187px;
  overflow: hidden;
  ul {
    display: block;
    margin: 0px auto;
    position: relative;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    *zoom: 1;
    li {
      float: left;
      position: relative;
      a {
        display: block;
        font-family: "Trebuchet MS";
        font-size: 16px;
        color: #7f9910;
        text-decoration: none;
        height: 25px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        background-image: url("/img/csr/btn_snav_out.png");
        background-repeat: no-repeat;
        padding: 17px 0px 0px 0px;
        position: relative;
        top: -7px;
      }
    }
  }
}

.textshadow .header-csr ul li a {
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
}

.header-csr ul li {
  &.selected a, a:hover {
    top: 2px;
    background-image: url("/img/csr/btn_snav_over.png");
    color: #ffffff;
  }
}

.textshadow .header-csr ul li {
  &.selected a, a:hover {
    text-shadow: 0px -1px 0px rgba(255, 255, 255, 0.5);
  }
}

.header-csr ul li {
  &.header-csr-top {
    width: 348px;
    height: 3px;
    top: 0px;
    left: 11px;
    position: absolute;
    z-index: 19;
    span {
      width: 100%;
      height: 3px;
      display: block;
      background-image: url("/img/csr/bdr_snav_bg.png");
      background-position: top left;
      background-repeat: repeat-x;
    }
  }
  &.header-csr-start {
    display: block;
    width: 11px;
    height: 20px;
    z-index: 21;
    background-image: url("/img/csr/bdr_snav_left.png");
    background-position: top left;
    background-repeat: no-repeat;
    left: 0px;
    top: 0px;
    position: absolute;
    span {
      display: block;
      width: inherit;
      height: inherit;
    }
  }
  &.header-csr-end {
    display: block;
    width: 14px;
    height: 20px;
    z-index: 22;
    background-image: url("/img/csr/bdr_snav_right.png");
    background-position: top left;
    background-repeat: no-repeat;
    right: 0px;
    top: 0px;
    position: absolute;
    span {
      display: block;
      width: inherit;
      height: inherit;
    }
  }
  &.header-csr-why a {
    width: 126px;
    background-position: 0px 0px;
  }
}

.csstransitions.no-touch .header-csr ul li.header-csr-why a {
  transition: all 0.25s ease-out;
}

.header-csr ul li.header-csr-how a {
  width: 121px;
  background-position: -127px 0px;
}

.csstransitions.no-touch .header-csr ul li.header-csr-how a {
  transition: all 0.25s ease-out;
}

.header-csr ul li.header-csr-what a {
  width: 125px;
  background-position: -248px 0px;
}

.csstransitions.no-touch .header-csr ul li.header-csr-what a {
  transition: all 0.25s ease-out;
}

@media screen and (max-width: 960px) {
  #header {
    position: relative;
  }
  .header-csr, .header-left {
    display: none;
  }
  .header-logo {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 190px;
    height: 120px;
    left: 0px;
    background-image: url("/img/common/logo_960.png");
  }
  .mobile-menu {
    background-image: url("/img/common/hdr-yellow-65.png");
    background-repeat: repeat-x;
    background-position: 0px 0px;
    display: block;
    width: 100%;
    height: 65px;
    z-index: 2;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
    -moz-box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px;
    &:after, &:before {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    *zoom: 1;
    ul {
      display: table;
      height: inherit;
      float: right;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      *zoom: 1;
      li {
        height: inherit;
        float: left;
        background-image: url("/img/common/hdr-green-65.png");
        background-repeat: repeat-x;
        width: 70px;
        a {
          display: block;
          width: inherit;
          height: inherit;
          text-decoration: none;
          background-position: center center;
          background-repeat: no-repeat;
          span {
            display: none;
          }
        }
        &.icon-promotion a {
          background-image: url("/img/common/btn_menu_promos_960.png");
        }
        &.icon-recipe a {
          background-image: url("/img/common/btn_menu_recipes_960.png");
        }
        &:first-child {
          background-image: url("/img/common/hdr-yellow-65.png");
          background-repeat: repeat-x;
          padding-top: 3px;
          padding-right: 0px;
          div {
            width: 60px;
            height: 60px;
            display: block;
            background-image: url("/img/common/btn_menu_960.png");
            background-repeat: no-repeat;
            background-position: 0px 0px;
            padding: 0px;
          }
        }
      }
    }
  }
  .header-nav {
    width: 100%;
    height: auto;
    position: relative;
    top: 0px;
    z-index: 1;
    display: none;
    overflow: hidden;
    background-color: #869916;
    .container {
      width: inherit;
      position: relative;
      margin-left: 0px;
      background-color: #869916;
      background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #a5b82c), color-stop(100%, #869916));
      background-image: -webkit-linear-gradient(#a5b82c 20%, #869916);
      background-image: -moz-linear-gradient(#a5b82c 20%, #869916);
      background-image: -o-linear-gradient(#a5b82c 20%, #869916);
      background-image: linear-gradient(#a5b82c 20%, #869916);
      .holder {
        padding-top: 0px;
        background-image: none;
        ul {
          height: auto;
          width: inherit;
          display: block;
          li {
            float: none;
            padding: 0px;
            background-image: none;
            border-top: 1px solid #5e6b0f;
            border-bottom: 1px solid #b6c273;
            &:first-child {
              border-top: 0px;
            }
            &.selected a {
              background-image: none;
            }
            a {
              font-family: "Trebuchet MS";
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              padding: 13px 20px 15px 20px;
              text-decoration: none;
              display: block;
              text-align: center;
              text-transform: none;
              -webkit-font-smoothing: antialiased;
            }
          }
        }
      }
    }
  }
  .textshadow .header-nav .container .holder ul li a {
    text-shadow: 0px -2px 1px rgba(0, 0, 0, 0.15);
  }
  .header-nav .container .holder ul li {
    a:hover {
      background-image: none !important;
    }
    &.mobile-language {
      display: block;
      width: 100%;
      border: 0px;
      border-top: 1px solid #5e6b0f;
      ul {
        width: auto;
        display: table;
        margin: 0px auto;
        border: 0px;
        &:after, &:before {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        *zoom: 1;
        li {
          float: left;
          width: auto;
          border: 0px;
          background-image: url("/img/common/hdr_nav_divider_960.png");
          background-repeat: no-repeat;
          background-position: center left;
          &:first-child {
            background-image: none;
          }
          a {
            font-size: 20px;
            padding: 12px 12px 16px 16px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .header-right {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .header-logo {
    background-image: url("/img/common/logo_520.png");
    width: 90px;
    height: 72px;
    left: 10px;
  }
  .mobile-menu {
    height: 40px;
    ul li {
      width: 50px;
      &.icon-promotion a {
        background-image: url("/img/common/btn_menu_promos_520.png");
      }
      &.icon-recipe a {
        background-image: url("/img/common/btn_menu_recipes_520.png");
      }
      &:first-child {
        background-image: none;
        padding-top: 5px;
        padding-right: 5px;
        text-align: center;
        div {
          width: 30px;
          height: 30px;
          background-image: url("/img/common/btn_menu_520.png");
          margin: 0px auto;
        }
      }
    }
  }
  .header-nav .container .holder ul li {
    a {
      font-size: 18px;
      padding: 13px 20px 11px 20px;
    }
    &.mobile-language ul li {
      background-image: url("/img/common/hdr_nav_divider_520.png");
      background-repeat: no-repeat;
      background-position: center left;
      &:first-child {
        background-image: none;
      }
      a {
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
        padding: 9px 8px 9px 11px;
      }
    }
  }
  .header-right {
    display: none;
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .header-logo {
    background-image: url("/img/common/logo_960@2x.png");
    background-size: 150px 120px;
  }
  .mobile-menu ul li:first-child div {
    background-image: url("/img/common/btn_menu_960@2x.png");
    background-size: 60px 60px;
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .header-logo {
    background-image: url("/img/common/logo_520@2x.png");
    background-size: 90px 72px;
  }
  .mobile-menu ul li:first-child div {
    background-image: url("/img/common/btn_menu_960.png");
    background-size: 30px 30px;
  }
}

.home-connect {
  display: block;
  width: 100%;
  height: 232px;
  position: relative;
  text-align: center;
  overflow: hidden;
  .home-container {
    display: block;
    width: 100%;
    max-width: 1400px;
    height: inherit;
    background-image: url("/img/common/connect_bg.png");
    background-color: #6a7911;
    background-repeat: repeat-x;
    background-position: 0px 0px;
    overflow: hidden;
    margin: 0px auto;
    position: relative;
  }
  h3 {
    font-family: "plume_adregular";
    font-size: 70px;
    color: #ffffff;
    margin: 0px auto;
    padding: 36px 0px 4px 0px;
    position: relative;
    bottom: 5px;
    -webkit-font-smoothing: antialiased;
  }
}

.textshadow .home-connect h3 {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.18);
}

.home-connect {
  .shadow {
    width: 100%;
    height: 10px;
    background-image: url("/img/common/connect_shadow.png");
    background-repeat: repeat-x;
    background-position: 0px 0px;
    display: block;
    position: absolute;
    z-index: 3;
    bottom: 0px;
    left: 0px;
  }
  .container {
    display: table;
    height: 104px;
    background-image: url("/img/landing/connect_divider.png");
    background-position: top center;
    background-repeat: no-repeat;
    margin: 14px auto 0px auto;
    ul {
      display: table;
      margin: 0px auto;
      padding: 29px 20px 0px 20px;
      text-align: center;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      *zoom: 1;
      li {
        float: left;
        margin: 0px auto;
        ul {
          display: table;
          margin: 0px auto;
          padding: 0px;
          &:after, &:before {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          *zoom: 1;
          li {
            float: left;
            padding: 5px;
            width: auto;
            a {
              -webkit-backface-visibility: hidden;
              text-decoration: none;
              span {
                -webkit-backface-visibility: hidden;
                text-align: left;
                span {
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}

.no-touch .home-connect .container ul li ul li a:hover span {
  background-image: inherit;
}

.csstransitions .home-connect .container ul li ul li a {
  transition: background 0.25s ease-out;
  span {
    transition: background 0.25s ease-out;
  }
}

.home-connect .container ul li ul li.newsletter a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
  background-position: 100% -35px;
  color: #809b0d;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px 0px;
    padding-left: 10px;
    > span {
      padding-left: 32px;
      background-image: url("/img/common/btn_icon_newsletter.png");
      background-position: 5px 6px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.newsletter a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.newsletter a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.newsletter a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_newsletter.png");
    background-position: 5px 6px;
  }
}

.home-connect .container ul li ul li.faq a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
  background-position: 100% -35px;
  color: #809b0d;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px 0px;
    padding-left: 10px;
    > span {
      padding-left: 32px;
      background-image: url("/img/common/btn_icon_faq.png");
      background-position: 5px 6px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.faq a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.faq a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.faq a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_faq.png");
    background-position: 5px 6px;
  }
}

.home-connect .container ul li ul li.contact-us a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
  background-position: 100% -35px;
  color: #809b0d;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px 0px;
    padding-left: 10px;
    > span {
      padding-left: 32px;
      background-image: url("/img/common/btn_icon_contact.png");
      background-position: 5px 6px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.contact-us a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.contact-us a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.contact-us a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_contact.png");
    background-position: 5px 6px;
  }
}

.home-connect .container ul li ul li.facebook a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  background-position: 100% -105px;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px -70px;
    padding-left: 15px;
    > span {
      padding-left: 28px;
      background-image: url("/img/common/btn_icon_facebook.png");
      background-position: 0px 5px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.facebook a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.facebook a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.facebook a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_facebook.png");
    background-position: 0px 5px;
  }
}

.home-connect .container ul li ul li.instagram a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  background-position: right -352px;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px -317px;
    padding-left: 15px;
    > span {
      padding-left: 28px;
      background-image: url("/img/common/btn_icon_instagram.png");
      background-position: 0px 5px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.instagram a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.instagram a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.instagram a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_instagram.png");
    background-position: 0px 5px;
  }
}

.home-connect .container ul li ul li.twitter a {
  background-image: url("/img/common/btn_small.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 35px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  background-position: 100% -175px;
  span {
    background-image: url("/img/common/btn_small.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 35px;
    width: inherit;
    padding-left: 18px;
    line-height: 35px;
  }
  > span {
    background-position: 0px -140px;
    padding-left: 14px;
    > span {
      padding-left: 28px;
      background-image: url("/img/common/btn_icon_twitter.png");
      background-position: 0px 5px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.twitter a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.twitter a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.twitter a:hover {
  background-image: url("/img/common/btn_small_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_twitter.png");
    background-position: 0px 5px;
  }
}

.home-connect .container ul li ul li.youtube a {
  background-image: url("/img/common/btn_small_grey.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-right: 18px;
  display: table;
  height: 34px;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-family: "Trebuchet MS";
  font-weight: bold;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
  -webkit-font-smoothing: antialiased;
  background-position: 100% -34px;
  span {
    background-image: url("/img/common/btn_small_grey.png");
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    height: 34px;
    width: inherit;
    padding-left: 18px;
    line-height: 34px;
  }
  > span {
    background-position: 0px 0px;
    padding-left: 14px;
    > span {
      padding-left: 28px;
      background-image: url("/img/common/btn_icon_youtube.png");
      background-position: 0px 6px;
      background-repeat: no-repeat;
      padding-right: 0px;
    }
  }
}

@media all and (max-width: 960px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.youtube a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

@media all and (max-width: 520px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .home-connect .container ul li ul li.youtube a {
    padding-right: 17px;
    span {
      padding-left: 17px;
    }
  }
}

.no-touch .home-connect .container ul li ul li.youtube a:hover {
  background-image: url("/img/common/btn_small_grey_over.png");
  > span > span {
    background-image: url("/img/common/btn_icon_youtube.png");
  }
}

.home-connect .container.collapse {
  width: 100%;
  ul {
    width: auto;
    li {
      width: inherit;
      float: none;
      text-align: center;
      ul {
        width: inherit;
        li {
          width: 100%;
          text-align: center;
          padding: 5px 0px;
          a {
            margin: 0px auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .home-connect {
    height: auto;
  }
  .fr_CH .home-connect h3 {
    font-size: 40px;
  }
  .fr_FR .home-connect h3 {
    font-size: 50px;
  }
  .de_DE .home-connect h3 {
    font-size: 46px;
  }
  .home-connect .container {
    width: inherit;
    padding-bottom: 20px;
    ul {
      padding-top: 19px;
      li {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .home-connect h3 {
    font-size: 40px;
    padding-top: 41px;
    padding-bottom: 14px;
  }
  .it_IT .home-connect h3 {
    font-size: 1.9em;
  }
  .fr_FR .home-connect h3, .fr_CH .home-connect h3 {
    font-size: 30px;
  }
  .de_DE .home-connect h3 {
    font-size: 27px;
  }
  .home-connect .container ul {
    width: 100%;
  }
}

#footer {
  height: 84px;
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  z-index: 3;
  .qr-footer-url {
    display: none;
  }
  .home-container {
    display: block;
    width: 100%;
    height: inherit;
    max-width: 1400px;
    margin: 0px auto;
    position: relative;
    background-image: url("/img/common/footer_bg.png");
    background-repeat: repeat-x;
    background-position: 0px 0px;
    .footer-logo {
      position: absolute;
      top: -48px;
      right: 5px;
      background-image: url("/img/common/logo_footer.png");
      width: 150px;
      height: 100px;
      span {
        display: none;
      }
    }
    ul {
      margin: 0px auto 0px auto;
      display: table;
      padding: 29px 0px 10px 0px;
      &:after, &:before {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      *zoom: 1;
      li {
        float: left;
        padding: 0px 20px;
        background-image: url("/img/common/footer_divider.png");
        background-position: center left;
        background-repeat: no-repeat;
        &:first-child {
          background-image: none;
        }
        a {
          font-family: "Trebuchet MS";
          font-size: 18px;
          color: #8aa710;
          text-decoration: none;
        }
      }
    }
  }
}

.textshadow #footer .home-container ul li a {
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.no-touch #footer .home-container ul li a:hover {
  text-decoration: underline;
}

#footer .home-container p {
  font-family: "Trebuchet MS";
  font-size: 13px;
  font-style: italic;
  color: #937200;
  letter-spacing: 1px;
}

.textshadow #footer .home-container p {
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.it_IT #footer .home-container p {
  font-size: 10px;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  #footer {
    background-image: none;
    height: auto;
    .home-container {
      background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #a5b82b), color-stop(100%, #869916));
      background-image: -webkit-linear-gradient(#a5b82b, #869916);
      background-image: -moz-linear-gradient(#a5b82b, #869916);
      background-image: -o-linear-gradient(#a5b82b, #869916);
      background-image: linear-gradient(#a5b82b, #869916);
      padding: 0px;
      margin: 0px;
      .footer-logo {
        display: none;
      }
      ul {
        height: auto;
        width: inherit;
        display: block;
        padding: 0px;
        margin: 0px;
        li {
          float: none;
          padding: 0px;
          background-image: none;
          border-top: 1px solid #5e6b0f;
          border-bottom: 1px solid #b6c273;
          &:first-child {
            border-top: 0px;
          }
          &:last-child {
            border-bottom: 1px solid #5e6b0f;
          }
          a {
            font-family: "Trebuchet MS";
            font-size: 18px;
            color: #ffffff;
            padding: 14px 20px;
            text-decoration: none;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  .textshadow #footer .home-container ul li a {
    text-shadow: 0px -2px 2px rgba(0, 0, 0, 0.25);
  }
  #footer .home-container {
    ul li a:hover {
      background-image: none !important;
    }
    p {
      background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffc902), color-stop(100%, #ffc401));
      background-image: -webkit-linear-gradient(#ffc902, #ffc401);
      background-image: -moz-linear-gradient(#ffc902, #ffc401);
      background-image: -o-linear-gradient(#ffc902, #ffc401);
      background-image: linear-gradient(#ffc902, #ffc401);
      border-top: 1px solid #ffdf67;
      font-size: 14px;
      padding: 14px 20px 9px 20px;
    }
  }
}

@media screen and (max-width: 520px) {
  #footer .home-container {
    ul li a {
      font-size: 16px;
      padding: 7px 20px;
    }
    p {
      font-size: 12px;
      padding-bottom: 8px;
    }
  }
}

body {
  background-image: url("/img/common/background.png");
  background-position: top center;
  background-repeat: repeat-y;
}

@media screen and (max-width: 960px) {
  body {
    background-image: url("/img/common/footer_bg_mobile.png");
    background-repeat: repeat-x;
    background-position: bottom left;
  }
}